import React, { useState, useEffect, useRef } from "react";
import { CSSTransition } from "react-transition-group";
import {
  Navbar,
  Container,
  Nav,
  Form,
  FormControl,
  Button,
  Dropdown,
  Image,
  Row,
  Col,
} from "react-bootstrap";
import axios from "./Api"; // Ensure this path is correct
import { useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";

const NavigationBar = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [adminAccount, setAdminAccount] = useState(null);
  const [userAccount, setUserAccount] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(true);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [countdown, setCountdown] = useState(null);
  const [message, setMessage] = useState("");
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [open, setOpen] = useState(false);
  const searchRef = useRef(null);

  const handleMouseEnter = () => setShow(true);
  const handleMouseLeave = () => setShow(false);

  useEffect(() => {
    const checkAuthentication = () => {
      const token = localStorage.getItem("authToken");
      if (!token) {
        setIsAuthenticated(false);
      }
    };

    const fetchAdminAccount = async () => {
      try {
        const token = localStorage.getItem("authToken");
        if (!token) {
          throw new Error("No token found");
        }

        const response = await axios.get("/admin-account", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        setAdminAccount(response.data);
      } catch (error) {
        console.log("No account Login, details:", error);
        setIsAuthenticated(false);
        // navigate("/LoginForm");
      }
    };

    const fetchUserAccount = async () => {
      try {
        const token = localStorage.getItem("authToken");
        if (!token) {
          throw new Error("No token found");
        }

        const response = await axios.get("/user-account", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        setUserAccount(response.data);
      } catch (error) {
        console.log("No account Login, details:", error);
        setIsAuthenticated(false);
        // navigate("/LoginForm");
      }
    };

    checkAuthentication();

    if (isAuthenticated) {
      fetchAdminAccount();
      fetchUserAccount();
    }
  }, [isAuthenticated, navigate]);

  const handleSearch = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.get(`/search?query=${searchQuery}`);
      console.log(response.data);
      setOpen(false); // Close the search form after a successful search
    } catch (error) {
      console.error("There was an error fetching the data!", error);
    }
  };

  const handleLogin = async (event) => {
    event.preventDefault();

    try {
      const response = await axios.post("/proses_login", {
        username,
        password,
      });

      if (
        response.data.message === "Admin login successful" &&
        response.data.token
      ) {
        localStorage.setItem("authToken", response.data.token);
        localStorage.setItem("userRole", "admin");

        setMessage("Admin login successful! Redirecting...");
        setCountdown(3);
        redirectToDashboard("/AdminDashboard");
      } else if (
        response.data.message === "User login successful" &&
        response.data.token
      ) {
        localStorage.setItem("authToken", response.data.token);
        localStorage.setItem("userRole", "user");

        setMessage("User login successful! Redirecting...");
        setCountdown(3);
        redirectToDashboard("/UserDashboard");
      } else {
        setMessage(
          response.data.message ||
            "Login failed. Please check your username and password."
        );
      }
    } catch (error) {
      console.error("Login error:", error);
      setMessage("An error occurred during login. Please try again.");
    } finally {
      setUsername("");
      setPassword("");
    }
  };

  const redirectToDashboard = (path) => {
    const countdownInterval = setInterval(() => {
      setCountdown((prevCountdown) => {
        if (prevCountdown <= 1) {
          clearInterval(countdownInterval);
          navigate(path);
          return null;
        }
        return prevCountdown - 1;
      });
    }, 1000);
  };

  const handleLogout = async () => {
    try {
      const token = localStorage.getItem("authToken");
      await axios.post(
        "/logout",
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      );
      localStorage.removeItem("authToken");
      setAdminAccount(null);
      setIsAuthenticated(false);
      navigate("/LoginForm");
      alert("You have been logged out!");
    } catch (error) {
      alert("There was an error during logout!");
      console.error("There was an error during logout!", error);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <Navbar variant="dark" expand="lg" className="fixed-top">
      <Container>
        <Navbar.Brand href="/">
          <i className="fas fa-gamepad me-2"></i> Wiki Game
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href="/">HOME</Nav.Link>
            <Nav.Link href="https://linktr.ee/ImamFauji">CONTACT</Nav.Link>
            <Nav.Link href="https://imamfauji.github.io/WebsiteImamF/">
              ABOUT ME
            </Nav.Link>
            {/* {!adminAccount ||
              (!userAccount && <Nav.Link href="/LoginForm">LOGIN</Nav.Link>)}
            {adminAccount && (
              <Nav.Link
                href={
                  adminAccount.role === "admin"
                    ? "/AdminDashboard"
                    : "/UserDashboard"
                }
              >
                DASHBOARD
              </Nav.Link>
            )}
            ,{" "}
            {userAccount && (
              <Nav.Link
                href={
                  userAccount.role === "user"
                    ? "/UserDashboard"
                    : "/UserDashboard"
                }
              >
                DASHBOARD
              </Nav.Link>
            )} */}
            {/* If no account is logged in, show the LOGIN link */}
            {!adminAccount && !userAccount && (
              <Nav.Link href="/LoginForm">LOGIN</Nav.Link>
            )}

            {/* If the logged-in account is an admin, show the admin dashboard link */}
            {adminAccount && adminAccount.role === "admin" && (
              <Nav.Link href="/AdminDashboard">DASHBOARD</Nav.Link>
            )}

            {/* If the logged-in account is a regular user, show the user dashboard link */}
            {userAccount && userAccount.role === "user" && (
              <Nav.Link href="/UserDashboard">DASHBOARD</Nav.Link>
            )}
          </Nav>
          <div className="d-flex align-items-center">
            <Button
              variant="outline-light"
              type="button"
              onClick={() => setOpen(!open)}
              aria-controls="search-form"
              aria-expanded={open}
            >
              <i className="fas fa-search"></i>
            </Button>
            <CSSTransition
              in={open}
              timeout={300}
              classNames="fade"
              unmountOnExit
            >
              <div
                ref={searchRef}
                className="search-container position-relative ms-2"
                id="search-form"
              >
                <Form className="d-flex" onSubmit={handleSearch}>
                  <FormControl
                    type="search"
                    placeholder="Search"
                    className="me-2"
                    aria-label="Search"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                  />
                  <Button type="submit" variant="outline-success">
                    Search
                  </Button>
                </Form>
              </div>
            </CSSTransition>
          </div>
          <Nav className="ms-1 navdropdown">
            <Dropdown align="end">
              <Dropdown.Toggle variant="outline-light" id="dropdown-basic">
                {adminAccount && (
                  <Image
                    src={
                      adminAccount?.displayPicture ||
                      "https://media.licdn.com/dms/image/v2/D5603AQH6VmISPewG5w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1704992132211?e=1730332800&v=beta&t=VxqfO_Ew59JnzQGxBGmrxSNwePiwHSUdW3jJ3woFCKc"
                    }
                    roundedCircle
                    className="me-1"
                    width={25}
                    height={25}
                  />
                )}
                {userAccount && (
                  <Image
                    src={
                      userAccount?.displayPicture ||
                      "https://img.freepik.com/free-vector/businessman-character-avatar-isolated_24877-60111.jpg?size=626&ext=jpg"
                    }
                    roundedCircle
                    className="me-1"
                    width={25}
                    height={25}
                  />
                )}
                {!adminAccount && !userAccount && (
                  <i className="fa-solid fa-user"></i>
                )}{" "}
                {adminAccount?.username || userAccount?.username || "Guest"}
              </Dropdown.Toggle>
              <Dropdown.Menu
                className="loginBG"
                style={{
                  maxHeight: "48vh",
                  overflow: "auto",
                  minWidth: "25vh",
                }}
              >
                <Dropdown.ItemText>
                  <Row>
                    {(adminAccount || userAccount) && (
                      <Col>
                        <div className="text-center">
                          {adminAccount && (
                            <Image
                              src={
                                adminAccount.displayPicture ||
                                "https://media.licdn.com/dms/image/v2/D5603AQH6VmISPewG5w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1704992132211?e=1730332800&v=beta&t=VxqfO_Ew59JnzQGxBGmrxSNwePiwHSUdW3jJ3woFCKc"
                              }
                              roundedCircle
                              width={100}
                              height={100}
                            />
                          )}

                          {userAccount && (
                            <Image
                              src={
                                userAccount.displayPicture ||
                                "https://img.freepik.com/free-vector/businessman-character-avatar-isolated_24877-60111.jpg?size=626&ext=jpg"
                              }
                              roundedCircle
                              width={100}
                              height={100}
                            />
                          )}
                        </div>
                      </Col>
                    )}

                    <Col>
                      {!adminAccount && !userAccount && (
                        <Form onSubmit={handleLogin} className="text-white">
                          <Form.Group controlId="inputName" className="mb-3">
                            <Form.Label>Username</Form.Label>
                            <Form.Control
                              type="text"
                              value={username}
                              onChange={(e) => setUsername(e.target.value)}
                            />
                          </Form.Group>
                          <Form.Group
                            controlId="inputPassword"
                            className="mb-3"
                          >
                            <Form.Label>Password</Form.Label>
                            <Form.Control
                              type="password"
                              value={password}
                              onChange={(e) => setPassword(e.target.value)}
                            />
                            <div className="message mt-3 text-white">
                              {message && <p>{message}</p>}
                              {countdown !== null && (
                                <p>Redirecting in {countdown} seconds</p>
                              )}
                            </div>
                          </Form.Group>
                          <Button
                            variant="success"
                            className="w-100"
                            type="submit"
                          >
                            Login
                          </Button>
                          <Form.Text style={{ color: "white" }}>
                            Don't Have Account?
                            <br />
                            Register Here!
                          </Form.Text>
                          <Button
                            variant="primary"
                            className="w-100"
                            href="/RegisterForm"
                          >
                            Sign Up
                          </Button>
                        </Form>
                      )}
                      {(adminAccount || userAccount) && (
                        <div
                          className="m-1 text-white p-2"
                          style={{
                            border: "1px solid white",
                            backgroundColor: "rgba(0, 0, 0, 0.64)",
                            border: "1px solid rgba(255,255,255,0.5)",
                            fontFamily: "monospace",
                            textShadow: "1px 1px 1px black",
                          }}
                        >
                          <div>
                            ID :{" "}
                            {adminAccount?.username ||
                              userAccount?.username ||
                              "N/A"}
                          </div>
                          <div>
                            <b>
                              Name :{" "}
                              {adminAccount?.name ||
                                userAccount?.name ||
                                "Guest User"}
                            </b>
                          </div>
                          <div className="mt-2 text-center">
                            {/* If admin is logged in, link to AdminDashboard */}
                            {adminAccount && adminAccount.role === "admin" && (
                              <div className="mt-2 text-center">
                                <a href="/AdminDashboard">
                                  <Button variant="primary">
                                    <i className="fa-solid fa-gear"></i> Profile
                                  </Button>
                                </a>
                              </div>
                            )}

                            {/* If user is logged in, link to UserDashboard */}
                            {userAccount && userAccount.role === "user" && (
                              <div className="mt-2 text-center">
                                <a href="/UserDashboard">
                                  <Button variant="primary">
                                    <i className="fa-solid fa-gear"></i> Profile
                                  </Button>
                                </a>
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                    </Col>
                  </Row>
                </Dropdown.ItemText>
                <Dropdown.Divider />
                <div className="mt-1 d-flex flex-column p-1">
                  {(adminAccount || userAccount) && (
                    <Button className="btn btn-success" onClick={handleLogout}>
                      Logout
                    </Button>
                  )}
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavigationBar;
