import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Container, Row, Col, Button, Card } from "react-bootstrap";
import axios from "../Api"; // Assuming you have configured axios instance
import "../../style/content.css";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [countdown, setCountdown] = useState(null);
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  const handleLogin = async (event) => {
    event.preventDefault();

    try {
      const response = await axios.post(
        "/proses_login",
        {
          username,
          password,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      // Axios automatically checks for HTTP status codes and throws an error for non-2xx status codes
      const data = response.data;
      console.log("Login response data:", data);

      if (data.message === "Admin login successful" && data.token) {
        localStorage.setItem("authToken", data.token);
        localStorage.setItem("userRole", "admin");
        setMessage("Admin login successful! Redirecting...");
        setCountdown(3);
        redirectToDashboard("/AdminDashboard");
      } else if (data.message === "User login successful" && data.token) {
        localStorage.setItem("authToken", data.token);
        localStorage.setItem("userRole", "user");
        setMessage("User login successful! Redirecting...");
        setCountdown(3);
        redirectToDashboard("/UserDashboard");
      } else {
        setMessage(
          data.message ||
            "Login failed. Please check your username and password."
        );
      }
    } catch (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        const errorData = error.response.data;
        setMessage(
          errorData.message ||
            `Login failed with status: ${error.response.status}`
        );
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error("Login error:", error);
        setMessage("An error occurred during login. Please try again.");
      }
    } finally {
      setUsername("");
      setPassword("");
    }
  };

  const redirectToDashboard = (path) => {
    const countdownInterval = setInterval(() => {
      setCountdown((prevCountdown) => {
        if (prevCountdown <= 1) {
          clearInterval(countdownInterval);
          navigate(path); // Redirect based on role
          return null;
        }
        return prevCountdown - 1;
      });
    }, 1000);
  };

  return (
    <Container className="d-flex justify-content-center align-items-center min-vh-100">
      <Row className="w-100">
        <Col xs={12} md={6} lg={4} className="mx-auto">
          <Card className="p-4 shadow-sm loginBG">
            <Card.Body>
              <Card.Title className="text-center">
                <b>LOGIN</b>
              </Card.Title>
              <Form onSubmit={handleLogin}>
                <Form.Group controlId="inputName" className="mb-3">
                  <Form.Label>Username</Form.Label>
                  <Form.Control
                    type="text"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                  />
                </Form.Group>
                <Form.Group controlId="inputPassword" className="mb-3">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </Form.Group>
                <Button variant="success" className="w-100 mb-1" type="submit">
                  Login
                </Button>
                <Form.Text className="formText" muted>
                  Don't Have Account?
                  <br />
                  Register Here!
                </Form.Text>
                <Button
                  variant="primary"
                  className="w-100"
                  href="/RegisterForm"
                >
                  Sign Up
                </Button>
              </Form>
              <div className="message mt-3 text-center">
                {message && <p>{message}</p>}
                {countdown !== null && (
                  <p>Redirecting in {countdown} seconds</p>
                )}
                
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Login;
